import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiCallsService } from 'src/app/core/api-calls/api-calls.service';

@Injectable({
  providedIn: 'root'
})
export class BotanicalNamesService {
  botanicalNames: any[];

  constructor(private apiCallsService: ApiCallsService, private _http: HttpClient) {
    this.botanicalNames = [];
  }

  async getTreeBotanicalNames() {
    this.apiCallsService.getTreeBotNames().subscribe(names => {
      names.forEach(name => {
        name.type = "tree";
        this.botanicalNames.push(name);
      });
    })
  }

  async getShrubBotanicalNames() {
    this.apiCallsService.getShrubBotNames().subscribe(names => {
      names.forEach(name => {
        name.type = "shrub";
        this.botanicalNames.push(name);
      });
    })
  }

  setBotanicalNamesArray() {
    this.getTreeBotanicalNames().then(() => {
      this.getShrubBotanicalNames();
    })
  }

  getBotanicalNamesArray() {
    return this.botanicalNames;
  }
}
