import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AutocompleteLibModule } from "angular-ng-autocomplete";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./shared/header/header.component";
import { MainScreenComponent } from "./features/main-screen/main-screen.component";
import { SearchTreeComponent } from "./features/search/search-tree/search-tree.component";
import { SearchShrubComponent } from "./features/search/search-shrub/search-shrub.component";
import { SearchSpecimenComponent } from "./features/search/search-specimen/search-specimen.component";
import { ResultsShrubComponent } from "./features/results/results-shrub/results-shrub.component";
import { ResultsTreeComponent } from "./features/results/results-tree/results-tree.component";
import { ResultsSpecimenComponent } from "./features/results/results-specimen/results-specimen.component";
import { SpinnerComponent } from "./shared/spinner/spinner.component";
import { GeneralComponent } from "./features/results/result-categories/general/general.component";
import { GrowthDataComponent } from "./features/results/result-categories/growth-data/growth-data.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { MainPartnersComponent } from "./features/main-screen/main-partners/main-partners.component";
import { TeamComponent } from "./features/team/team.component";
import { PartnersComponent } from "./features/partners/partners.component";
import { MoaComponent } from "./features/moa/moa.component";
import { MainMoaComponent } from './features/main-screen/main-moa/main-moa.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainScreenComponent,
    SearchTreeComponent,
    SearchShrubComponent,
    ResultsShrubComponent,
    ResultsTreeComponent,
    MoaComponent,
    SpinnerComponent,
    GeneralComponent,
    GrowthDataComponent,
    FooterComponent,
    MainPartnersComponent,
    TeamComponent,
    PartnersComponent,
    SearchSpecimenComponent,
    ResultsSpecimenComponent,
    MainMoaComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    AutocompleteLibModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
