import { Component, OnInit } from '@angular/core';
import { Shrub } from '../../../models/payload/shrub/shrub';
import { PayloadService } from 'src/app/core/services/payload/payload.service';

@Component({
	selector: 'app-search-shrub',
	templateUrl: './search-shrub.component.html',
	styleUrls: ['./search-shrub.component.css']
})
export class SearchShrubComponent implements OnInit {
	climates = ["Dry", "Wet"];
	sizes = ["Small", "Medium", "Large", "Very Large"];
	locations = ["Cultivation", "Forest", "Home gardens"];

	shrub: Shrub;
	shrubPayload: Shrub;
	showResults: Boolean;
	disableFields: Boolean;

	constructor(private payloadService: PayloadService) {
		this.shrub = new Shrub();
		this.shrubPayload = new Shrub();
		this.showResults = false;
		this.disableFields = false;
	}

	ngOnInit() {
	}

	onSubmit() {
		this.cleanPayload();
		this.payloadService.setShrubFilters(this.shrubPayload);
		this.showResults = true;
		this.disableFields = true;

		document.getElementById("submit-button-shrub").style.display = "none";
		document.getElementById("reset-button-shrub").style.display = "block";
	}

	cleanPayload() {
		this.setShrubSize();
		this.setLocation();
		this.setClimate();
	}

	hideSections() {
		this.showResults = false;
		this.disableFields = false;
		document.getElementById("submit-button-shrub").style.display = "block";
		document.getElementById("reset-button-shrub").style.display = "none";
	}

	setShrubSize() {
		if (this.shrub.tree_size != 'null' && this.shrub.tree_size != null) {
			this.shrubPayload.tree_size = this.shrub.tree_size;
		} else delete this.shrubPayload.tree_size;
	}

	setClimate() {
		if (this.shrub.climate != 'null' && this.shrub.climate != null) {
			this.shrubPayload.climate = this.shrub.climate;
		} else delete this.shrubPayload.climate;
	}

	setLocation() {
		if (this.shrub.suitable_location != 'null' && this.shrub.suitable_location != null) {
			this.shrubPayload.suitable_location = this.shrub.suitable_location;
		} else delete this.shrubPayload.suitable_location;
	}
}