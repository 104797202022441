import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PeopleDataService {
  people: any[];

  constructor() {
    this.setPeopleArray();
  }

  setPeopleArray() {
    let name = [
      "Upul Subasinghe",
      "Madhawa Kalanajith",
      "Missaka Hettiarachchi",
      "Kasuni Amarasinghe",
      "Kavindu Munugoda",
    ];

    let description = this.setDescriptions();
    let imageURLs = this.setImageURLs();

    this.people = [];
    let person = {};

    for (let i = 0; i < name.length; i++) {
      person = this.setPeopleObject(name[i], description[i], imageURLs[i]);
      this.people.push(person);
    }
  }

  getPeopleArray() {
    return this.people;
  }

  setPeopleObject(name, description, imgURL) {
    return { name: name, description: description, imgURL };
  }

  setDescriptions() {
    //Upul Description
    let descriptionUpul =
      "Upul Subasinghe is a Professor in Forestry at the Department of Forestry and" +
      " Environmental Science of the University of Sri Jayewardenepur in Sri Lanka, where he teaches and" +
      " conducts research on forest management and restoration, forest certification, and non-timber products of forest trees." +
      " He completed his PhD (Forest Management) in University of Bangor, Wales, UK." +
      " His main research interests lie in aromatic products of Santalum, Aquilaria, and Gyrinops species." +
      " Currently, Dr. Subasinghe is the Director of the Centre for Forestry and Environment, which was established" +
      " to conduct applied research on the forestry and environment sectors. He also engages with WWF training" +
      " and awareness programs on land restoration using nature-based methods, especially with private sector organizations" +
      " in Sri Lanka, which utilize large portions of watersheds for agriculture plantation management.";

    //Madhawa Description
    let descriptionMadhawaP1 =
      "Madhawa Kalanajith is a BSc. Honors Degree holder in Software Engineering at the University of" +
      " Sri Lanka Institute of Information Technology, Sri Lanka. His skills mainly focus on Full-Stack Web Development." +
      " He is proficient in popular development technologies such as, AngularJS, ReactJS, NodeJS, Java, MongoDB etc." +
      " Currently, Madhawa is a frontend developer at";

    let descriptionMadhawaP2 =
      ", an online platform for music broadcasting" +
      " and event hosting/streaming. He has also conducted research on ";

    let descriptionMadhawa = [descriptionMadhawaP1, descriptionMadhawaP2];

    //Missaka Description
    let descriptionMissaka =
      "Dr. Missaka Hettiarachchi is a Senior Fellow at the World Wildlife Fund (WWF) USA," +
      " and the global training lead of WWF's Flood Green Guide Program. Missaka is a Civil and Environmental Engineer" +
      " by training and holds a PhD in Environmental Planning from the University of Queensland, Australia." +
      " He has worked in post-disaster environmental management in many countries including Sri Lanka, Nepal, Haiti," +
      " the Philippines and Australia. He's also actively engaged in teaching and research in environmental policy and" +
      " governance, as an Adjunct Associate Professor at the James Cook University, Australia, a Visiting Scholar at the" +
      " Northwestern University, USA and Visiting Lecturer at the University of Moratuwa, Sri Lanka.";

    //Kasuni Description
    let descriptionKasuni =
      "Kasuni Amarasinghe is an Honors Degree holder in Applied Sciences focused on Environmental" +
      " Management and Forestry University of Sri Jayewardenepura, Sri Lanka. She has conducted a research" +
      " on ‘Identification of Nature Based Methods for Conservation of Lower Watershed of Kalu River Basin of" +
      " Sri Lanka’ under the supervision of Prof. Upul Subasinghe and Dr. Missaka Hettiarachchi. She is currently" +
      " establishing herself as an Environmentalist/Project coordinator by fulltime working with a one of lead" +
      " consultant companies in Sri Lanka. She has contributed in developing environmental reports for several" +
      " development projects in Sri Lanka by her knowledge.";

    //Kavindu Description
    let descriptionKavindu =
      "Kavindu Munugoda is an Honors Degree holder in" +
      " Applied Sciences focused on Biotechnology at the University of Sri Jayewardenepura, Sri Lanka." +
      " He has a demonstrated history of working in the brewery industry." +
      " Presently, pursuing his PhD at the Department of Forestry and Environmental Science at the same University." +
      " He conducts his research focused on aromatic plants utilised as mosquito repellents in traditional ayurvedic medicine." +
      " He is also establishing himself as a professional researcher by working with the Centre for Forestry and Environment," +
      " which conducts applied research on forest management and restoration, forest certification," +
      " and non-timber products of forest trees.";

    return [
      descriptionUpul,
      descriptionMadhawa,
      descriptionMissaka,
      descriptionKasuni,
      descriptionKavindu,
    ];
  }

  getDescriptions() {
    return this.setDescriptions();
  }

  setImageURLs() {
    let commonString = "../../../assets/images/team/";

    let imageUpul = commonString + "upul_s.jpg";
    let imageMadhawa = commonString + "madhawa_s.jpg";
    let imageMissaka = commonString + "missaka_h.jpg";
    let imageKasuni = commonString + "kasuni_a.jpg";
    let imageKavindu = commonString + "kavindu_m.jpg";

    return [imageUpul, imageMadhawa, imageMissaka, imageKasuni, imageKavindu];
  }
}
