import { Component, OnInit } from '@angular/core';
import { PeopleDataService } from 'src/app/core/services/people/people-data.service';

@Component({
  selector: 'app-main-partners',
  templateUrl: './main-partners.component.html',
  styleUrls: ['./main-partners.component.css']
})
export class MainPartnersComponent implements OnInit {
  sendMailP1: String;
  sendMailP2: String;
  descriptions: any[];

  constructor(peopleDataService: PeopleDataService) {  
    this.sendMailP1 = "https://mail.google.com/mail/?view=cm&fs=1&to=restoration@tropicaltreesearch.com"
    this.sendMailP2 = "https://mail.google.com/mail/?view=cm&fs=1&to=smmksubasinghe@gmail.com"

    this.descriptions = peopleDataService.getDescriptions();
  }

  ngOnInit() {
  }

}
