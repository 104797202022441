import { Injectable } from "@angular/core";
import { Shrub } from "src/app/models/payload/shrub/shrub";
import { Specimen } from "src/app/models/payload/specimen/specimen";
import { Tree } from "src/app/models/payload/tree/tree";

@Injectable({
	providedIn: "root"
})
export class PayloadService {
	shrubFilters: Shrub;
	treeFilters: Tree;

	specimenType: String;
	specimenFilters: Specimen;

	constructor() {}

	//Shrub setters and getters
	setShrubFilters(filters) {
		this.shrubFilters = filters;
	}

	getShrubFilters() {
		return this.shrubFilters;
	}

	//Tree setters and getters
	setTreeFilters(filters) {
		this.treeFilters = filters;
	}
	
	getTreeFilters() {
		return this.treeFilters;
	}

	//Specimen setters and getters
	setSpecimenFilters(filters) {
		this.treeFilters = filters;
	}

	getSpecimenFilters() {
		return this.treeFilters;
	}

	setSpecimenType(type) {
		this.specimenType = type;
	}

	getSpecimenType() {
		return this.specimenType;
	}
}
