import { Component, OnInit } from '@angular/core';
import { PeopleDataService } from 'src/app/core/services/people/people-data.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {
  people: any[];

  constructor(peopleDataService: PeopleDataService) {
    this.people = peopleDataService.getPeopleArray();
  }

  ngOnInit() {
  }
}
