export class Specimen {
	climate: String;
	suitable_location: String;
	tree_size: String;
	botanical_name: String;

	constructor(botanical_name?) {
		if (botanical_name) {
			this.climate = 'null';
			this.suitable_location = 'null';
			this.tree_size = 'null';
			this.botanical_name = botanical_name;
		} else {
			this.climate = 'null';
			this.suitable_location = 'null';
			this.tree_size = 'null';
			this.botanical_name = 'null';
		}
	}
}
