import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-growth-data",
	templateUrl: "./growth-data.component.html",
	styleUrls: [ "./growth-data.component.css" ]
})
export class GrowthDataComponent implements OnInit {
	@Input() data: any;

	constructor() {}

	ngOnInit() {
		this.data.tree_size = this.setArrayToString(this.data.tree_size);
		this.data.climate = this.setArrayToString(this.data.climate);
		this.data.suitable_location = this.setArrayToString(this.data.suitable_location);
		this.cleanEmptyFields();
	}

	setArrayToString(array) {
		let treeSize = "";

		if (array.length != 1) {
			treeSize = array[0];
			for (let i = 1; i < array.length; i++) {
				treeSize += " / " + array[i];
			}
			return treeSize;
		} else return array[0];
	}

	cleanEmptyFields() {
		if (!this.data.height) this.data.height = "Not Available";
		if (!this.data.diameter) this.data.diameter = "Not Available";
		if (!this.data.growth_rate) this.data.growth_rate = "Not Available";
		if (!this.data.light) this.data.light = "Not Available";
	}
}
