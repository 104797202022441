import { Component, OnInit } from "@angular/core";
import * as data from "../../../assets/data/total_plant_list.json";

@Component({
  selector: "app--moa",
  templateUrl: "./moa.component.html",
  styleUrls: ["./moa.component.css"],
})
export class MoaComponent implements OnInit {
  moaData: {
    sinhala_name: string;
    english_name: string;
    scientific_name: string;
    family: string;
    habitat: string;
    plant_part: string;
    methods_of_application: string;
  }[] = (data as any).default;

  constructor() {}

  ngOnInit() {}
}
