import { Component, OnInit } from '@angular/core';
import { PayloadService } from 'src/app/core/services/payload/payload.service';
import { BotanicalNamesService } from 'src/app/core/services/specimen-names/botanical-names/botanical-names.service';
import { Specimen } from 'src/app/models/payload/specimen/specimen';

@Component({
	selector: 'app-search-specimen',
	templateUrl: './search-specimen.component.html',
	styleUrls: ['./search-specimen.component.css']
})
export class SearchSpecimenComponent implements OnInit {
	keyword: String;
	showResults: Boolean;
	disableFields: Boolean;
	botanicalNames: any[];

	specimenPayload: Specimen;
	specimen: Specimen;

	constructor(private botanicalNamesService: BotanicalNamesService, private payloadService: PayloadService) {
		this.keyword = "botanical_name"
		this.showResults = false;
		this.disableFields = false;
		this.botanicalNames = [];

		this.specimenPayload = new Specimen();
		this.specimen = new Specimen();
	}

	ngOnInit() {
		this.botanicalNames = this.botanicalNamesService.getBotanicalNamesArray();
		document.getElementById("submit-button-specimen").setAttribute('disabled', 'disabled');
	}

	onSubmit() {
		this.cleanPayload();
		this.payloadService.setSpecimenFilters(this.specimenPayload);

		this.showResults = true;
		this.disableFields = true;

		document.getElementById("submit-button-specimen").style.display = "none";
		document.getElementById("reset-button-specimen").style.display = "block";
	}

	selectEvent(event) {
		this.specimen.botanical_name = event.botanical_name;
		document.getElementById("submit-button-specimen").removeAttribute('disabled');
		this.payloadService.setSpecimenType(event.type);
	}

	onClearSearch(event) {
		document.getElementById("submit-button-specimen").setAttribute('disabled', 'disabled');
	}

	onChangeSearch(	event) {
		document.getElementById("submit-button-specimen").setAttribute('disabled', 'disabled');
	}

	hideSections() {
		this.showResults = false;
		this.disableFields = false;
		document.getElementById("submit-button-specimen").style.display = "block";
		document.getElementById("reset-button-specimen").style.display = "none";
	}

	cleanPayload() {
		this.setShrubSize();
		this.setLocation();
		this.setClimate();
		this.setBotanicalName();
	}

	setShrubSize() {
		if (this.specimen.tree_size != 'null' && this.specimen.tree_size != null) {
			this.specimenPayload.tree_size = this.specimen.tree_size;
		} else delete this.specimenPayload.tree_size;
	}
	
	setClimate() {
		if (this.specimen.climate != 'null' && this.specimen.climate != null) {
			this.specimenPayload.climate = this.specimen.climate;
		} else delete this.specimenPayload.climate;
	}

	setLocation() {
		if (this.specimen.suitable_location != 'null' && this.specimen.suitable_location != null) {
			this.specimenPayload.suitable_location = this.specimen.suitable_location;
		} else delete this.specimenPayload.suitable_location;
	}

	setBotanicalName() {
		if (this.specimen.botanical_name != 'null' && this.specimen.botanical_name != null) {
			this.specimenPayload.botanical_name = this.specimen.botanical_name;
		} else delete this.specimenPayload.botanical_name;
	}
}
