import { Component, OnInit } from "@angular/core";
import { Shrub } from "src/app/models/return/shrub/shrub";
import { PayloadService } from "src/app/core/services/payload/payload.service";
import { ApiCallsService } from "src/app/core/api-calls/api-calls.service";

@Component({
	selector: "app-results-shrub",
	templateUrl: "./results-shrub.component.html",
	styleUrls: [ "./results-shrub.component.css" ]
})
export class ResultsShrubComponent implements OnInit {
	results: Shrub[];

	generalInfo: any[];
	growthInfo: any[];

	arrLength: Boolean;
	showResults: Boolean;
	showSpinner: Boolean;

	constructor(private payloadService: PayloadService, private apiCallsService: ApiCallsService) {
		this.showResults = false;
		this.showSpinner = true;
	}

	ngOnInit() {
		var familyName: String;

		this.apiCallsService.getShrubResults(this.payloadService.getShrubFilters()).subscribe((result) => {
			this.results = result;
			if (this.results.length == 0) {
				setTimeout(() => {
					this.showResults = true;
					this.showSpinner = false;
				}, 2000);

				this.arrLength = false;
			} else {
				this.arrLength = true;

				setTimeout(() => {
					this.showResults = true;
					this.showSpinner = false;
				}, 2000);

				this.generalInfo = [];
				this.growthInfo = [];

				this.results.forEach((result) => {
					familyName = result.botanical_name;
					result.modalId = this.setModalIds(familyName);
					result.generalId = this.setGeneralIds(familyName);
					result.growthId = this.setGrowthIds(familyName);

					this.generalInfo.push(this.setGeneralInfo(result));
					this.growthInfo.push(this.setGrowthInfo(result));
				});
			}
		});
	}

	setModalIds(familyName) {
		var nameArray: any;
		var modalId: String[];

		modalId = [];
		nameArray = familyName.split(" ");
		modalId.push("#dataModal" + nameArray[0] + nameArray[1]);
		modalId.push("dataModal" + nameArray[0] + nameArray[1]);

		return modalId;
	}

	setGeneralIds(familyName) {
		var nameArray: any;
		var generalId: String[];

		generalId = [];
		nameArray = familyName.split(" ");
		generalId.push("general" + nameArray[0] + nameArray[1]);
		generalId.push("general" + nameArray[0] + nameArray[1] + "-tab");
		generalId.push("#general" + nameArray[0] + nameArray[1]);

		return generalId;
	}

	setGrowthIds(familyName) {
		var nameArray: any;
		var growthId: String[];

		growthId = [];
		nameArray = familyName.split(" ");
		growthId.push("growth" + nameArray[0] + nameArray[1]);
		growthId.push("growth" + nameArray[0] + nameArray[1] + "-tab");
		growthId.push("#growth" + nameArray[0] + nameArray[1]);

		return growthId;
	}

	setGeneralInfo(data) {
		return {
			family: data.family,
			botanical_name: data.botanical_name,
			common_name: data.common_name,
			conservation_status: data.conservation_status,
			engineering_benefits: data.engineering_benefits,
			use: data.use
		};
	}

	setGrowthInfo(data) {
		return {
			tree_size: data.tree_size,
			height: data.height,
			diameter: data.diameter,
			climate: data.climate,
			elevation: data.elevation,
			suitable_location: data.suitable_location,
			growth_rate: data.growth_rate,
			light: data.light
		};
	}
}
