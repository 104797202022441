export class Shrub {
	climate: String;
	suitable_location: String;
	tree_size: String;

	constructor() {
		this.climate = 'null';
		this.suitable_location = 'null';
		this.tree_size = 'null';
	}
}