import { Component, OnInit } from '@angular/core';
import { Tree } from 'src/app/models/return/tree/tree';
import { Shrub } from 'src/app/models/return/shrub/shrub';
import { PayloadService } from 'src/app/core/services/payload/payload.service';
import { ApiCallsService } from 'src/app/core/api-calls/api-calls.service';

@Component({
  selector: 'app-results-specimen',
  templateUrl: './results-specimen.component.html',
  styleUrls: ['./results-specimen.component.css']
})
export class ResultsSpecimenComponent implements OnInit {
  shrubResults: Shrub[];
  treeResults: Tree[];
  result: any;

  specimenType: String;

  generalInfo: any;
  growthInfo: any;

  arrLength: Boolean;
  showResults: Boolean;
  showSpinner: Boolean;

  constructor(private payloadService: PayloadService, private apiCallsService: ApiCallsService) {
    this.showResults = false;
    this.showSpinner = true;
  }

  ngOnInit() {
    this.specimenType = this.payloadService.getSpecimenType();
    if (this.specimenType == 'shrub') {
      this.setShrubData();
    } else {
      this.setTreeData();
    }
  }

  setShrubData() {
    this.apiCallsService.getShrubResults(this.payloadService.getSpecimenFilters()).subscribe((result) => {
      this.shrubResults = result;
      if (this.shrubResults.length == 0) {
        setTimeout(() => {
          this.showResults = true;
          this.showSpinner = false;
        }, 2000);

        this.arrLength = false;
      } else {
        this.arrLength = true;

        setTimeout(() => {
          this.showResults = true;
          this.showSpinner = false;
        }, 2000);

        this.result = this.shrubResults[0];

        this.generalInfo = this.setGeneralInfo(this.result);
        this.growthInfo = this.setGrowthInfo(this.result);
      }
    });
  }

  setTreeData() {
    this.apiCallsService.getTreeResults(this.payloadService.getSpecimenFilters()).subscribe((result) => {
      this.treeResults = result;
      if (this.treeResults.length == 0) {
        setTimeout(() => {
          this.showResults = true;
          this.showSpinner = false;
        }, 2000);

        this.arrLength = false;
      } else {
        this.arrLength = true;

        setTimeout(() => {
          this.showResults = true;
          this.showSpinner = false;
        }, 2000);

        this.result = this.treeResults[0];

        this.generalInfo = this.setGeneralInfo(this.result);
        this.growthInfo = this.setGrowthInfo(this.result);
      }
    });
  }

  setGeneralInfo(data) {
    return {
      family: data.family,
      botanical_name: data.botanical_name,
      common_name: data.common_name,
      conservation_status: data.conservation_status,
      engineering_benefits: data.engineering_benefits,
      use: data.use
    };
  }

  setGrowthInfo(data) {
    return {
      tree_size: data.tree_size,
      height: data.height,
      diameter: data.diameter,
      climate: data.climate,
      elevation: data.elevation,
      suitable_location: data.suitable_location,
      growth_rate: data.growth_rate,
      light: data.light
    };
  }
}
