import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MainScreenComponent } from "./features/main-screen/main-screen.component";
import { SearchTreeComponent } from "./features/search/search-tree/search-tree.component";
import { SearchShrubComponent } from "./features/search/search-shrub/search-shrub.component";
import { MoaComponent } from "./features/moa/moa.component";
import { TeamComponent } from "./features/team/team.component";
import { PartnersComponent } from "./features/partners/partners.component";
import { SearchSpecimenComponent } from "./features/search/search-specimen/search-specimen.component";

const routes: Routes = [
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "home", component: MainScreenComponent },
  { path: "team", component: TeamComponent },
  { path: "partners", component: PartnersComponent },
  { path: "search/tree", component: SearchTreeComponent },
  { path: "search/shrub", component: SearchShrubComponent },
  { path: "search/specimen", component: SearchSpecimenComponent },
  { path: "moa", component: MoaComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
