import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Shrub } from 'src/app/models/return/shrub/shrub';
import { Tree } from 'src/app/models/return/tree/tree';

@Injectable({
	providedIn: 'root'
})
export class ApiCallsService {
	private shrubFilterURL = 'https://treesearch-backend.herokuapp.com/treeSearch/getFilterShrubs';
	private treeFilterURL = 'https://treesearch-backend.herokuapp.com/treeSearch/getFilterTrees';

	private treeBotNameURL = 'https://treesearch-backend.herokuapp.com/treeSearch/getAllTreeBotNames';
	private shrubBotNameURL = 'https://treesearch-backend.herokuapp.com/treeSearch/getAllShrubBotNames';

	constructor(private _http: HttpClient) {}

	public getShrubResults(filters): Observable<any> {
		return this._http.post<Shrub[]>(this.shrubFilterURL, filters);
	}

	public getTreeResults(filters): Observable<any> {
		return this._http.post<Tree[]>(this.treeFilterURL, filters);
	}

	public getTreeBotNames(): Observable<any> {
		return this._http.get<Tree[]>(this.treeBotNameURL);
	}

	public getShrubBotNames(): Observable<any> {
		return this._http.get<Tree[]>(this.shrubBotNameURL);
	}
}
