import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-general",
	templateUrl: "./general.component.html",
	styleUrls: [ "./general.component.css" ]
})
export class GeneralComponent implements OnInit {
	@Input() data: any;

	constructor() {}

	ngOnInit() {
		this.cleanEmptyFields();
	}

	cleanEmptyFields() {
		if (!this.data.engineering_benefits) this.data.engineering_benefits = "Not Available";
		if (!this.data.use) this.data.use = "Not Available";
	}
}
