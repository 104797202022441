import { Component } from '@angular/core';
import { BotanicalNamesService } from 'src/app/core/services/specimen-names/botanical-names/botanical-names.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  constructor(private botanicalNamesService: BotanicalNamesService) {
    this.botanicalNamesService.setBotanicalNamesArray();
  }

  onActivate() {
    window.scrollTo(0, 0);
  }
}
