import { Component, OnInit } from "@angular/core";
import { Tree } from "../../../models/payload/tree/tree";
import { PayloadService } from "src/app/core/services/payload/payload.service";

@Component({
	selector: "app-search-tree",
	templateUrl: "./search-tree.component.html",
	styleUrls: [ "./search-tree.component.css" ]
})
export class SearchTreeComponent implements OnInit {
	climates = [ "Dry", "Wet" ];
	sizes = [ "Small", "Medium", "Large", "Very Large" ];
	locations = [ "Cultivation", "Forest", "Home gardens" ];

	tree: Tree;
	treePayload: Tree;
	showResults: Boolean;
	disableFields: Boolean;

	constructor(private payloadService: PayloadService) {
		this.tree = new Tree();
		this.treePayload = new Tree();
		this.showResults = false;
		this.disableFields = false;
	}

	ngOnInit() {}

	onSubmit() {
		this.cleanPayload();
		this.payloadService.setTreeFilters(this.treePayload);
		this.showResults = true;
		this.disableFields = true;

		document.getElementById("submit-button").style.display = "none";
		document.getElementById("reset-button").style.display = "block";
	}

	cleanPayload() {
		this.setTreeSize();
		this.setLocation();
		this.setClimate();
	}

	hideSections() {
		this.showResults = false;
		this.disableFields = false;
		document.getElementById("submit-button").style.display = "block";
		document.getElementById("reset-button").style.display = "none";
	}

	setTreeSize() {
		if (this.tree.tree_size != "null" && this.tree.tree_size != null) {
			this.treePayload.tree_size = this.tree.tree_size;
		} else delete this.treePayload.tree_size;
	}

	setClimate() {
		if (this.tree.climate != "null" && this.tree.climate != null) {
			this.treePayload.climate = this.tree.climate;
		} else delete this.treePayload.climate;
	}

	setLocation() {
		if (this.tree.suitable_location != "null" && this.tree.suitable_location != null) {
			this.treePayload.suitable_location = this.tree.suitable_location;
		} else delete this.treePayload.suitable_location;
	}
}
